import React, { useEffect } from 'react';
import './TriggerDesktopAppOpenPage.css';

const OpenAppButton = () => {
  return (
    <a href="electric-desktop://mdm" id="open-desktop-app-btn">
      Open the Electric application
    </a>
  );
};

const TriggerDesktopAppOpenPage = () => {
  // Redirect user to electric-desktop://mdm when the page loads
  useEffect(() => {
    window.location.href = 'electric-desktop://mdm';
  });
  return (
    <div className="app-div">
      <div className="app-text-container">
        <img
          alt="desktop app download icon"
          src="/assets/images/appDownload.svg"
        />
        <h1 className="app-h1 app-text">Opening Electric Desktop</h1>
        <p className="app-p app-text">
          Click Open Electric Desktop on the dialog shown by your browser. If
          you don't see a dialog, click the open button below.
        </p>
        <div className="open-app-button">
          <OpenAppButton />
        </div>
        <p className="app-p app-text">
          Don't have the app? Download it here:{' '}
          <a href="/app">App Download Page</a>
        </p>
      </div>
    </div>
  );
};

export default TriggerDesktopAppOpenPage;
