import {
  ARCHITECTURES,
  getIsMac,
  getMacArchitecture,
  getSemanticVersionFromWindowsRelease,
} from './file-extension-helpers';

const { REACT_APP_DESKTOP_APP_S3_BUCKET_URL } = process.env;

const Versions = ['mac-arm', 'mac-intel', 'win'] as const;
type Version = (typeof Versions)[number];

export type DownloadURLsMap = Record<Version, string>;

const getDownloadURLWindows = async (): Promise<string> => {
  const architectureURL = `${REACT_APP_DESKTOP_APP_S3_BUCKET_URL}/win32/x64`;
  const url = `${architectureURL}/RELEASES`;
  const response = await fetch(url);
  if (response.status !== 200) {
    throw new Error('Error fetching releases JSON');
  }
  const releaseText = await response.text();
  const semanticVersion = getSemanticVersionFromWindowsRelease(releaseText);
  return `${architectureURL}/Electric Desktop-${semanticVersion} Setup.exe`;
};

const getDownloadURLsMac = async (): Promise<{
  'mac-arm': string;
  'mac-intel': string;
}> => {
  const getUrl = async (arch: 'arm64' | 'x64'): Promise<string> => {
    const archUrl = `${REACT_APP_DESKTOP_APP_S3_BUCKET_URL}/darwin/${arch}`;
    const url = `${archUrl}/RELEASES.json`;
    const response = await fetch(url);
    if (response.status !== 200) {
      throw new Error('Error fetching releases JSON');
    }
    const { currentRelease } = await response.json();
    return `${archUrl}/Electric Desktop-${currentRelease}-${arch}.dmg`;
  };
  return {
    'mac-arm': await getUrl('arm64'),
    'mac-intel': await getUrl('x64'),
  };
};

export const getDownloadLinks = async (): Promise<DownloadURLsMap> => {
  return {
    ...(await getDownloadURLsMac()),
    win: await getDownloadURLWindows(),
  };
};

export function getSystemVersion(): Version {
  if (!getIsMac()) {
    return 'win';
  }

  if (getMacArchitecture() === ARCHITECTURES.ARM) {
    return 'mac-arm';
  }

  return 'mac-intel';
}
