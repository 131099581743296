import DownloadDropdownButton from './DownloadDropdownButton';
import './DesktopAppPage.css';

const DesktopAppPage = () => {
  return (
    <main className="container">
      <section className="row app-header">
        <img
          className="logo"
          alt="Electric logo"
          src="/assets/images/logo_electric_white.png"
        />
        <p className="app-p">
          Need to unenroll from Electric for Desktop?{' '}
          <a href="/app/disconnect">View Instructions</a>
        </p>
      </section>
      <section className="app-content">
        <div className="app-download">
          <div>
            <h1 className="app-h1">Download Electric for Desktop</h1>
            <p className="download-app-text">
              Keep your laptop running smoothly with Electric.
            </p>
            <DownloadDropdownButton />
          </div>
          <div>
            <img
              alt="Laptop with icons"
              src="/assets/images/laptop-app-features.png"
            />
          </div>
        </div>
        <div className="app-benefits row">
          <div>
            <h3>What is Electric for Desktop?</h3>
            <p>
              Electric is your go-to hub for accessing everything you need to
              stay productive.
            </p>
            <p>
              From work applications to essential security functions, it's
              designed to simplify your day and keep you focused on what
              matters.
            </p>
          </div>
          <div>
            <h3>Key Features</h3>
            <ul>
              <li>
                <strong>One-Click Access</strong> to all your company's helpful
                links.
              </li>
              <li>
                <strong>24/7 IT Support</strong>, Powered by AI. Get instant
                assistance from our support bot.
              </li>
              <li>
                <strong>Security policies</strong> ensuring your company's data
                stays safe.
                <br />
                <p className="small-text">(With the Electric Pro Plan)</p>
              </li>
            </ul>
          </div>
          <div>
            <h3>Rest Assured</h3>
            <p>
              <strong>Your privacy matters to us.</strong> Electric does not
              access sensitive information like your passwords, emails, or
              browsing history.
            </p>
            <p>
              You can confidently use the app, knowing your personal data stays
              personal.
            </p>
          </div>
        </div>
        <div className="row">
          <img
            alt="Electric desktop app window with icons"
            src="/assets/images/electric-app-window.png"
          />
          <div>
            <h1 className="app-download-h1">Helpful Links</h1>
            <p className="app-p">
              Quickly find company resources, tools, and guides curated just for
              you.
            </p>
          </div>
        </div>
        <div className="row">
          <div>
            <h1 className="app-download-h1">Gigawatt, Your AI IT Assistant</h1>
            <p className="app-p">
              Get instant support, IT guidance, and expert tips at your
              fingertips.
            </p>
          </div>
          <img
            alt="Gigawatt AI assistant chat with customer"
            src="/assets/images/gigawatt.png"
          />
        </div>
        <div className="row">
          <img
            alt="Electric support center"
            src="/assets/images/support-center.png"
          />
          <div>
            <h1 className="app-download-h1">Your Company's Support Center</h1>
            <p className="app-p">
              Quickly connect with the right people for help with apps, devices,
              connectivity, and more.
            </p>
          </div>
        </div>
      </section>
    </main>
  );
};

export default DesktopAppPage;
