import './DisconnectAppPage.css';

const DisconnectAppButton = () => {
  return (
    <a
      href="electric-desktop://disconnect-device"
      id="open-desktop-app-disconnect">
      Open Electric for Desktop
    </a>
  );
};

const DisconnectAppPage = () => {
  return (
    <main className="page-container">
      <img
        className="logo"
        alt="Electric logo"
        src="/assets/images/logo_electric_white.png"
      />
      <section className="disconnect-app-content">
        <div>
          <h1 className="disconnect-app-h1 disconnect-app-text">
            How to unenroll from Electric for Desktop
          </h1>
          <div className="numbered-steps-wrapper">
            <div>
              <div>
                <div className="num-circle">1</div>
              </div>
              <p className="disconnect-app-text">
                Click the button below to get started. This will open Electric
                for Desktop.
              </p>
            </div>
            <div>
              <div>
                <div className="num-circle">2</div>
              </div>
              <p className="disconnect-app-text">
                Log in to your Electric account using your credentials.
              </p>
            </div>
            <div>
              <div>
                <div className="num-circle">3</div>
              </div>
              <p className="disconnect-app-text">
                Click on the “Disconnect device” button to unenroll your device.
              </p>
            </div>
          </div>
          <p className="disconnect-app-text small">
            This process should be straightforward and quick. If you encounter
            any difficulties or have any questions, please don't hesitate to
            contact our support team at{' '}
            <a href="mailto:product-support@electric.ai">
              product-support@electric.ai
            </a>
          </p>
          <div className="disconnect-app-button">
            <DisconnectAppButton />
          </div>
        </div>
        <div>
          <img
            src="/assets/images/laptop-app-features.png"
            alt="laptop with icons"
            width="600px"
          />
        </div>
      </section>
    </main>
  );
};

export default DisconnectAppPage;
