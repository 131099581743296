import { useEffect, useMemo, useState } from 'react';
import './DownloadDonePage.css';
import {
  DownloadURLsMap,
  getDownloadLinks,
  getSystemVersion,
} from './getDownloadLinks';

const nameMap: Record<string, string> = {
  'mac-arm': 'Mac (Apple Silicon)',
  'mac-intel': 'Mac (Intel)',
  win: 'Windows',
} as const;

const DownloadDonePage = () => {
  const preferredVersion = useMemo(() => getSystemVersion(), []);
  const [downloadUrlMap, setDownloadUrlMap] = useState<DownloadURLsMap | null>(
    null
  );

  useEffect(() => {
    const getDownloadLinksAndSetState = async () => {
      const downloadLinks = await getDownloadLinks();
      setDownloadUrlMap(downloadLinks);
    };

    getDownloadLinksAndSetState();
  }, [setDownloadUrlMap]);

  const preferredDownloadLink = downloadUrlMap?.[preferredVersion] ?? null;
  const otherDownloadLinks =
    downloadUrlMap &&
    Object.entries(downloadUrlMap)
      .map(([version, url]) => {
        if (version === preferredVersion) {
          return null;
        }
        return (
          <a
            key={version}
            id={`electric-desktop-app-download-from-done-page-${version}`}
            href={url}
            target="_blank"
            rel="noreferrer">
            Download for {nameMap[version]}
          </a>
        );
      })
      .filter(Boolean);

  return (
    <main className="page-container">
      <img
        className="logo"
        alt="Electric logo"
        src="/assets/images/logo_electric_white.png"
      />
      <section className="download-done-content">
        <div>
          <h1 className="download-done-h1 download-done-text">
            Done downloading?
          </h1>
          <h1 className="download-done-h1 download-done-text">Next...</h1>
          <div className="numbered-steps-wrapper">
            <div>
              <div>
                <div className="num-circle">1</div>
              </div>
              <p className="download-done-text">
                Once downloaded, open the Electric installer in your Downloads
                folder
              </p>
            </div>
            <div>
              <div>
                <div className="num-circle">2</div>
              </div>
              <p className="download-done-text">
                Drag the Electric icon to your Applications folder
              </p>
            </div>
            <div>
              <div>
                <div className="num-circle">3</div>
              </div>
              <p className="download-done-text">
                Launch and{' '}
                <a
                  id="knowledge-base-install-app-instructions-link"
                  href="https://support.electric.ai/electricithub/electric-it-hub-installing-the-desktop-app"
                  target="_blank"
                  rel="noreferrer">
                  follow instructions
                </a>{' '}
                to connect your device to Electric
              </p>
            </div>
          </div>
          {preferredDownloadLink && (
            <p className="download-done-text small">
              Not working?{' '}
              <a
                id={`electric-desktop-app-download-from-done-page-${preferredVersion}`}
                href={preferredDownloadLink}
                target="_blank"
                rel="noreferrer">
                {`Restart the download for ${nameMap[preferredVersion]}`}
              </a>
            </p>
          )}
          <p className="download-done-text small">
            Need another app version? {otherDownloadLinks?.[0]} or{' '}
            {otherDownloadLinks?.[1]}
          </p>
        </div>
        <div>
          <img
            src="/assets/images/laptop-app-features.png"
            alt="laptop with icons"
            width="600px"
          />
        </div>
      </section>
    </main>
  );
};

export default DownloadDonePage;
