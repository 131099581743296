import React, { useState, useEffect, useRef, useMemo } from 'react';

import './DownloadDropdownButton.css';
import {
  DownloadURLsMap,
  getDownloadLinks,
  getSystemVersion,
} from './getDownloadLinks';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(
  ref: React.RefObject<HTMLDivElement>,
  cb: () => void
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event?.target as Node)) {
        cb();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, cb]);
}

const downloadAndRedirect = (
  e: React.MouseEvent<HTMLAnchorElement>,
  url: string
) => {
  e.preventDefault();
  window.location.href = url;
  setTimeout(() => {
    window.location.href = '/app/download-done';
  }, 500);
};

const DownloadButton = ({ version, downloadUrl, children }) => {
  return (
    <a
      id={`electric-desktop-app-download-${version}`}
      href={downloadUrl ?? '#'}
      onClick={(e) => downloadAndRedirect(e, downloadUrl)}
      target="_blank"
      rel="noreferrer">
      {children}
    </a>
  );
};

const DownloadDropdownButton = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const openDropdownClass = isDropdownOpen ? 'dropdown-open' : '';

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    setIsDropdownOpen(false);
  });

  const [downloadUrlMap, setDownloadUrlMap] = useState<DownloadURLsMap | null>(
    null
  );
  useEffect(() => {
    const getDownloadLinksAndSetState = async () => {
      const downloadLinks = await getDownloadLinks();
      setDownloadUrlMap(downloadLinks);
    };

    getDownloadLinksAndSetState();
  }, [setDownloadUrlMap]);

  const buttonMap = {
    'mac-arm': (
      <DownloadButton
        key="mac-arm"
        version="mac-arm"
        downloadUrl={downloadUrlMap?.['mac-arm'] ?? null}>
        Download for Mac - Apple Silicon
      </DownloadButton>
    ),
    'mac-intel': (
      <DownloadButton
        key="mac-intel"
        version="mac-intel"
        downloadUrl={downloadUrlMap?.['mac-intel']}>
        Download for Mac - Intel
      </DownloadButton>
    ),
    win: (
      <DownloadButton
        key="win"
        version="win"
        downloadUrl={downloadUrlMap?.['win']}>
        Download for Windows
      </DownloadButton>
    ),
  };

  const preferredVersion = useMemo(() => getSystemVersion(), []);
  const PreferredButton = buttonMap[preferredVersion];
  const otherVersions = Object.keys(buttonMap).filter(
    (version) => version !== preferredVersion
  );

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className={`download-button ${openDropdownClass}`} ref={wrapperRef}>
      {PreferredButton}
      <button className="dropdown-toggle" onClick={handleDropdownClick}>
        <img
          alt="desktop app type toggle dropdown"
          src="/assets/images/chevron-down.svg"
          width="20px"
        />
      </button>
      <div className={`alternative-download-options ${openDropdownClass}`}>
        {otherVersions.map((version) => buttonMap[version])}
      </div>
    </div>
  );
};

export default DownloadDropdownButton;
