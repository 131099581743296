import './App.css';
import {
  DesktopAppPage,
  DisconnectAppPage,
  DownloadDonePage,
  MDMPage,
  TriggerDesktopAppOpenPage,
} from './components';

function App() {
  switch (window.location.pathname) {
    case '/app':
      return <DesktopAppPage />;
    case '/app/download-done':
      return <DownloadDonePage />;
    case '/open-desktop-app':
      return <TriggerDesktopAppOpenPage />;
    case '/app/disconnect':
      return <DisconnectAppPage />;
    default:
      return <MDMPage />;
  }
}

export default App;
